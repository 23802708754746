var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 px-2 justify-content-center align-items-center"},[_c('b-card',{staticClass:"w-50"},[_c('b-card-header',{staticClass:"text-align-center justify-content-center"},[_c('b-link',[_c('b-img',{staticClass:"logo-login justify-content-center align-items-center",attrs:{"src":require('@/assets/images/logo/intellyscale.svg')}})],1)],1),_c('b-card-body',[_c('b-alert',{attrs:{"variant":"success","show":_vm.hideForm}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',{staticClass:"h5 text-success"},[_vm._v(_vm._s(_vm.$t('Registration successfull!')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('A fresh verification link has been sent to your email address.'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('If you did not receive the email click the button below.'))+", ")]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"success","type":"submit","size":"sm"},on:{"click":_vm.resendVerificationEmail}},[_vm._v(" "+_vm._s(_vm.$t('Resend activiation email'))+" ")])],1)])]),(!_vm.hideForm)?_c('div',{},[_c('b-card-text',{staticClass:"mb-1 d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('Register now as an Expert to starts the adventure!'))+" 🚀 ")])],1):_vm._e(),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.hideForm)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Firstname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Firstname')},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('first_name'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.first_name[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Last name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Last name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Last name')},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('last_name'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.lastname[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Company')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Company')},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('company'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.company[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('email@domain.de')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(Object.keys(_vm.validation).includes('email'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.email[0])))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.passwordFieldType,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(Object.keys(_vm.validation).includes('password'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.password[0])))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Confirm Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Confirm Password'),"rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"state":errors.length > 0 ? false : null,"type":_vm.passwordconfirmFieldType,"placeholder":_vm.$t('Confirm Password')},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordconfirmToggleIcon},on:{"click":_vm.togglePasswordConfirmVisibility}})],1)],1),(Object.keys(_vm.validation).includes('password_confirmation'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.password_confirmation[0])))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('Privacy policy'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.privacy_policy),callback:function ($$v) {_vm.privacy_policy=$$v},expression:"privacy_policy"}},[_vm._v(" "+_vm._s(_vm.$t('I agree to'))+" "),_c('b-link',{attrs:{"href":_vm.privacy_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('privacy policy & terms')))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr'),_c('b-button',{attrs:{"variant":"success","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Sign up'))+" ")])],1)],1)],1):_vm._e()]}}])}),(!_vm.hideForm)?_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Already have an account?')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sign in instead')))])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }